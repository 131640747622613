<template>
  <v-app-bar
      color="#FF8EA0"
      dark
      density="compact"
  >

    <v-app-bar-nav-icon  class="text-white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-app-bar-title><router-link to="/" class="text-link text-white">은지네</router-link></v-app-bar-title>

    <template v-slot:append>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" @click="login_check">
            <v-icon class="text-white">
              mdi-account
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="this.access_token !== null"
          >
              <v-list-item title="로그아웃" value="로그아웃" @click="logout">
              </v-list-item>

          </v-list-item>
          <v-list-item
            v-else
          >
            <router-link to="/login" class="text-link">
              <v-list-item title="로그인" value="로그인">
              </v-list-item>
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>

    </template>

  </v-app-bar>

  <v-navigation-drawer
      class="bg-white"
      v-model="drawer"
      temporary
      touchless
  >
    <v-list>
      <router-link to="/article" class="text-link">
        <v-list-item title="기사" value="article">
        </v-list-item>
      </router-link>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
import router from "@/router";

export default {
  name: "ej-head",

  data () {
    return {
      access_token : null,
      drawer: null,
      user_menu:false
    }
  },

  methods : {
    login_check() {
      this.access_token = localStorage.getItem("access_token");
    },

    logout() {
      localStorage.clear();
      this.access_token = null;
      router.push("/")
    }
  }

}
</script>

<style scoped>
.text-link {
  color: #000000;
  text-decoration: none;
}
</style>