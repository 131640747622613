import { createWebHistory, createRouter } from "vue-router";

import Home from '../view/Home.vue'
import Article from '../view/Article.vue'
import Login from '../view/Login.vue'

const routes = [
    {
        path: "/",
        name : "Home",
        component : Home
    },
    {
        path: "/article",
        name : "Article",
        component : Article
    },
    {
        path: "/login",
        name : "Login",
        component : Login
    },
    {
        path: "/:pathMatch(.*)*",
        redirect : "/"
        //component: { template: '<h1>404</h1>' }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;