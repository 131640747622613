<template>
  <div class="text-center">
    <v-overlay
        v-model="display"
        class="align-center justify-center"
        persistent
    >
        <v-progress-circular
            :size="64"
            color="#FF8EA0"
            indeterminate
        ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "ej-loading-api",
  data: () => ({
    display : true,
  })

}
</script>

<style scoped>

</style>