<template>
  <v-sheet class="mx-auto">
    <v-form fast-fail @submit.prevent>

      <v-responsive
          class="mx-auto"
      >
        <v-text-field
            label="ID"
            name="name"
            v-model="name"
        ></v-text-field>
      </v-responsive>

      <v-responsive
          class="mx-auto"
      >
        <v-text-field
            label="Password"
            type="password"
            name="password"
            v-model="password"
            hint="비밀번호를 입력하세요"
        ></v-text-field>
      </v-responsive>

      <v-btn type="submit" block class="mt-2" @click="onSubmit">로그인</v-btn>
    </v-form>
  </v-sheet>
</template>

<script>
//import router from "@/router";

import router from "@/router";

export default {
  setup() {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      router.push({path:"/"})
    }
  },
  name: "ej-login",
  data() {
    return {
     name:"",
     password : ""
    }
  },
  methods : {
    onSubmit() {
      this.$axios.post("https://api.jgihun.kr/login", {
        account : this.name,
        password : this.password
      }).then(response => {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        router.push({path:"/"})
      }).catch( (error) => {
        if(error.response.status === 401) {
          alert(error.response.data.message);
          localStorage.clear();
        } else if(error.response.status === 422) {
          alert("필수값이 입력되지 않았습니다.");
        }
        //alert(response.data.message);

      })
    },
    /*onSubmit1() {
      this.$axios.post("https://api.jgihun.kr/boot",null,{
        headers : {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(response => {

      }).catch(error => {
        console.log(error)
      });
    }*/
  },
}
</script>

<style scoped>

</style>