<template>
  <v-dialog v-model="this.showModal" persistent>
    <v-card>

        <v-alert
            prominent
            type="error"
            variant="outlined"
        >
          <strong>API</strong> 서버가 오프라인 상태입니다.

          <br/>

          <div class="text-body-2">
            잠시후 다시 시도해 주세요
          </div>
        </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ej-OfflineAPI",

  data() {
    return {
      showModal: true
    }
  }

}
</script>