<template>
  <v-card height="350px" variant="outlined">

    <v-card-title class="text-h5">
      <table>
        <tr>
          <td>
            <v-img
                v-bind:src="this.type === 'maple' ? require('@/assets/game/maple.png') : require('@/assets/game/suddenattack.png')"
                height="40"
                width="40"
                class="d-inline-block"
            >
            </v-img>
          </td>
          <td class="pa-1">
            {{ this.title }}
          </td>
        </tr>
      </table>
    </v-card-title>


    <v-tabs
        v-model="this.tab"
        bg-color="#FF8EA0"
        class="text-white"
    >
      <v-tab v-for="item in this.tabs" v-bind:key="item" :value="item">
        {{item}}
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="this.tab">

        <v-window-item v-for="(item,index) in this.tabs" v-bind:key="item" :value="item">
          <v-table>
            <tbody>

            <tr
                v-if="Array.isArray(this.items[index]) && this.items[index].length !== 4">

              <td>
                <v-alert
                    class="align-center"
                    color="error"
                    icon="$error"
                    text="서버가 점검중이거나 오류가 발생하였습니다."
                ></v-alert>
              </td>

            </tr>

            <tr
                v-else
                v-for="(item,index) in this.items[index]" v-bind:key="index"
            >
              <td><a v-bind:href="item.href" target="_blank" class="font-weight-bold">{{ item.title }}</a></td>
              <td><a v-bind:href="item.href" target="_blank">{{ item.time }}</a></td>
            </tr>
            </tbody>
          </v-table>

        </v-window-item>

      </v-window>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Home-Card",

  data() {
    return {
      tab : null
    }
  },

  props: {

    type : String,
    icon : String,
    tabs : Array,
    items : Object,
    where : String,
    title : String
  }

}
</script>

<style scoped>

</style>