<template>
  <v-app>
    <v-main v-if="this.apiOnline === true">

      <Head />
      <Basic />
    </v-main>

    <v-main v-else-if="this.apiOnline === false">

      <Head />
      <Offline />
    </v-main>

    <v-main v-else>
      <Head />

      <loading />

    </v-main>

  </v-app>
</template>

<script>

import Head from './layouts/Head.vue'
import loading from './loading/api.vue'
import Offline from './errors/OfflineAPI.vue'
import Basic from "./layouts/Basic.vue";


export default {
  name: 'App',

  components: {
    Head,
    Offline,
    loading,
    Basic
  },

  data: () => ({

    "apiOnline" : null
    //
  }),

  beforeMount() {

    this.$axios.get("https://api.jgihun.kr")
        .then( (response) => {

          let apiStatus = response.data.status;

          this.apiOnline = apiStatus === 200;

          //this.apiOnline = false;

        });

    const token = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (token) {
      this.$axios.get('https://api.jgihun.kr/user', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        console.log(response.data);
      }).catch(error => {
        if (error.response.status === 401) {
          // Access Token이 만료되었을 때 Refresh Token을 사용하여 새로운 Access Token을 발급받음
          this.$axios.post('https://api.jgihun.kr/user/refresh', {
            refresh_token: refreshToken,
          }).then(response => {
            localStorage.setItem('access_token', response.data.access_token);
            this.$access_token = localStorage.getItem('access_token');
          }).catch( () => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.clear();
          });
        } else {
          console.log(error);
        }
      });
    }

  }
}
</script>
