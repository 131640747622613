<template>
 <div>

     <v-row no-gutters>
       <v-col
           cols="12"
           xs="12"
           md="6"
           class="pa-2"
       >
         <v-sheet class="">

           <Card type="maple" icon="maple" :items="maple" :tabs="['본섭','테섭']" title="메이플스토리" />

         </v-sheet>
       </v-col>

       <v-col
           cols="12"
           xs="12"
           md="6"
           class="pa-2"
       >
         <v-sheet class="">

           <Card type="sa" icon="sa" :items="sa" :tabs="['공지','업데이트']" title="서든어택" />

         </v-sheet>
       </v-col>
     </v-row>
 </div>
</template>

<script>

import Card from "./item/Home-Card.vue"
export default {
  name: "ej-home",

  components: {
    Card
  },

  data() {
    return {
      "maple" : [],
      "sa" : [],
    }
  },

  beforeMount() {

    // 메이플 본섭 공지
    this.$axios.get("//api.jgihun.kr/game/maple-notice?type=original")
        .then( (response) => {
          this.maple[0] = JSON.parse(JSON.stringify(response.data));
        })
        .catch(() => {
          this.maple[0] = [];
        });

    // 메이플 테섭 공지
    this.$axios.get("//api.jgihun.kr/game/maple-notice?type=test")
        .then( (response) => {
          this.maple[1] = JSON.parse(JSON.stringify(response.data));
        })
        .catch(() => {
          this.maple[1] = [];
        });


    // 서든 공지
    this.$axios.get("//api.jgihun.kr/game/sa-notice?type=notice")
        .then( (response) => {
          this.sa[0] = JSON.parse(JSON.stringify(response.data));
        })
        .catch(() => {
          this.sa[0] = [];
        });

    // 서든 업데이트
    this.$axios.get("//api.jgihun.kr/game/sa-notice")
        .then( (response) => {
          this.sa[1] = JSON.parse(JSON.stringify(response.data));
        })
        .catch(() => {
          this.sa[1] = [];
        });

  }
}
</script>

<style scoped>

</style>